import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// Services
import { AuthenticationService } from 'app/core/services';

@Injectable()
export class PermissionsGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let canActivate = false;
        if (state.url !== '/' && this.authenticationService.isAuthenticated()) {
            const moduleName = this.getModuleName(route);
            if (this.authenticationService.hasPermission(moduleName, 'ACCESS')) {
                canActivate = true;
            } else if (this.authenticationService.hasPermission('forbidden', 'ACCESS')) {
                this.router.navigate(['forbidden']);
            }
        } else {
            return true;
        }
        return canActivate;
    }

    getModuleName(route): string {
        return route.routeConfig.path;
    }
}
