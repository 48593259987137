export enum PlatformUser {
  PAS = 'Productor',
  Workshop = 'Tallerista',
  AdjusterIntern = 'PeritoInterno',
  AdjusterExtern = 'PeritoExterno'
}

export let PlatformUserArray = [
  PlatformUser.PAS,
  PlatformUser.Workshop,
  PlatformUser.AdjusterIntern,
  PlatformUser.AdjusterExtern
];
