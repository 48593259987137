import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { Routing } from './app.routes';

// Jwt Module
import { JwtModule } from '@auth0/angular-jwt';
export function tokenGetter() {
  return window.localStorage.getItem('tk');
}

// ngx Toastr - toaster
import { ToastrModule } from 'ngx-toastr';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

// Services
import { AnalyticsService, AuthenticationService, FileDownloadService } from './core/services';

// Helpers
import { ScBrowserDetectHelper } from 'app/core/helpers/sc-browser-detect.helper';
import { ScFormHelper } from './core/helpers/sc-form.helper';

// Modules
import { CoreModule } from './core/core.module';
import { ScPipesModule } from 'app/shared/pipes/sc-pipes.module';
import { ScModalDialogModule, UiCoreComponentsModule } from '@sc/claim-components';
import { ScHeaderInfoModule } from './components/header-info/header-info.module';
import { ScDropdownModule } from 'app/shared/components/sc-dropdown/sc-dropdown.module';
import { ScNotificationsModule } from 'app/shared/components/sc-notifications/sc-notifications.module';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);

// Modals
import {
  ScServiceAgreementModalModule,
  ScNewsModalModule,
  ScVersionsModalModule,
  ScDownloadBaremoModalModule
} from 'app/shared/components/sc-modals';

import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';

import localEs from '@angular/common/locales/es';
import { ScTableModule } from './shared/components/sc-table/sc-table.module';
registerLocaleData(localEs);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    Routing,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    GalleryModule.forRoot(),
    ScPipesModule,
    UiCoreComponentsModule,
    ScServiceAgreementModalModule,
    ScNewsModalModule,
    ScDownloadBaremoModalModule,
    ScVersionsModalModule,
    ScHeaderInfoModule,
    ScDropdownModule,
    ScNotificationsModule,
    ScTableModule,
    ScModalDialogModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR' },
    AnalyticsService,
    AuthenticationService,
    ScBrowserDetectHelper,
    ScFormHelper,
    FileDownloadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
