import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External Libraries
import { JwtHelperService } from '@auth0/angular-jwt';

// Services
import { AnalyticsService } from 'app/core/services';

// Helpers
import { ApiClient } from 'app/core/helpers';

// Models
import { UserInfo } from 'app/shared/models';

// Environments
import { environment } from 'environments/environment';
import { PlatformUser, PlatformUserArray } from 'app/shared/models/platformUser.enum';

const TOKEN_STORAGE_KEY = 'tk';
const TOKEN_HASH_KEY = 'hash';
const USER_STORAGE_KEY = 'user';

@Injectable()
export class AuthenticationService {

  public jwtHelperService: JwtHelperService;

  constructor(
    private analyticsService: AnalyticsService,
    private api: ApiClient,
    private httpClient: HttpClient
  ) {
    this.jwtHelperService = new JwtHelperService();
  }

  // Authentication API

  login(email: string, password: string) {
    return this.httpClient.post(environment.apiAuthUrl + '/api/token/local', { email: email, password: password });
  }

  updatePassword(userName: string, newPassword: string, oldPassword: string, token: string) {
    return this.httpClient.post(environment.apiAuthUrl + '/api/users/' + userName + '/password', { oldPassword, newPassword, token });
  }

  sendResetPasswordMail(userName: string) {
    return this.sendMailByReason(userName, 'reset');
  }

  sendMailByReason(userName: string, reason: string) {
    return this.httpClient.post(environment.apiAuthUrl + '/api/users/' + userName + '/activation?reason=' + reason, {});
  }

  isEnabledPas(branch) {
    return this.api.get(`${environment.ca7claim}/pas/${branch}`);
  }

  logout(): void {
    this.analyticsService.clearAuthenticatedUser();
    this.removeUserDataFromLocalStorage();
    this.removeAuthenticationToken();
  }

  removeUserDataFromLocalStorage(): void {
    // TODO review if is needed to remove siniestros from storage
    window.localStorage.removeItem('siniestros');
    window.localStorage.removeItem(USER_STORAGE_KEY);
  }

  getAuthenticationToken(): string {
    return window.localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  getHashedPassword(): string {
    return window.localStorage.getItem(TOKEN_HASH_KEY);
  }
  setAuthenticationToken(token: string): void {
    window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
  }

  removeAuthenticationToken(): void {
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  isAuthenticated(token: string = this.getAuthenticationToken()): boolean {
    return token && this.isJwtValidToken(token) && !this.jwtHelperService.isTokenExpired(token);
  }

  isJwtValidToken(token: string): boolean {
    const parts = token.split('.');
    // 'JWT must have 3 parts' && 'Can decode the token'
    return parts.length === 3 && this.jwtHelperService.decodeToken(token);
  }

  // User API

  getUserInfo(token: string = this.getAuthenticationToken()) {
    return this.api.get(environment.apiClaim + '/api/user/info', null, { headers: { 'Authorization': 'Bearer ' + token } });
  }

  getCurrentUser(token: string = this.getAuthenticationToken()) {
    return this.api.get(environment.apiAuthUrl + '/api/users/current', null, { headers: { 'Authorization': 'Bearer ' + token } });
  }

  setClaimUserAgreements(token: string = this.getAuthenticationToken()) {
    // tslint:disable-next-line:max-line-length
    return this.api.get(environment.apiAuthUrl + '/api/users/current/agreements/claim', null, { headers: { 'Authorization': 'Bearer ' + token } });
  }

  getLocalStorageUserInfo(): UserInfo {
    return JSON.parse(window.localStorage.getItem(USER_STORAGE_KEY)) || new UserInfo();
  }

  setLocalStorageUserInfo(userInfo: UserInfo) {
    window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userInfo));
  }

  getDecodedUserInfo(token: string = this.getAuthenticationToken()): UserInfo {
    const decodedToken = this.jwtHelperService.decodeToken(token);
    return new UserInfo(decodedToken.UserId || '', this.getUserRole(token), this.getUserName(token));
  }

  getUserRole(
    token: string = this.getAuthenticationToken(),
    roleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  ): PlatformUser {
    const decodedToken = (token) ? this.jwtHelperService.decodeToken(token) : null;
    return (decodedToken) ? decodedToken[roleKey] : '';
  }

  getUserName(
    token: string = this.getAuthenticationToken(),
    nameKey = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
  ): string {
    const decodedToken = (token) ? this.jwtHelperService.decodeToken(token) : null;
    return (decodedToken) ? decodedToken[nameKey] : '';
  }

  getNews() {
    return this.api.get(environment.apiAuthUrl + '/api/news/claim');
  }

  setNews() {
    return this.api.post(environment.apiAuthUrl + '/api/news/claim', {});
  }

  isPlatformUser() {
    const validUsersRoles = PlatformUserArray;
    return validUsersRoles.indexOf(this.getUserRole()) !== -1;
  }

  hasPermission(module: string, permission: string): boolean {
    const PERMISSIONS = {
      Administrador: {
        authentication: ['ACCESS'],
        login: ['ACCESS'],
        global: [],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        statistics: ['ACCESS'],
        admin: ['ACCESS']
      },
      Anonymous: {
        authentication: ['ACCESS'],
        ['change-password']: ['ACCESS'],
        ['siniestros/detalle']: [],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [],
        siniestros: [],
        login: ['ACCESS'],
        ['reset-password']: ['ACCESS'],
        statistics: [],
        'siniestros/tracking': []
      },
      ClaimCenter: {
        authentication: ['ACCESS'],
        ['change-password']: [],
        ['siniestros/detalle']: [],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [],
        siniestros: [],
        login: [],
        ['reset-password']: [],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__VIEW',
          'CLAIM_DETAIL__COMPLETE_INFO__VIEW'
        ]
      },
      PeritoInterno: {
        authentication: [
          'ACCESS',
          'PLATFORM_NEWS',
          'LOGIN__AGREEMENT_SERVICE'
        ],
        ['change-password']: ['ACCESS'],
        ['siniestros/detalle']: [
          'ACCESS',
          'SINIESTRO_DETAIL__PERITO__VIEW'
        ],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [
          'HEADER__LOGOUT_BUTTON__VIEW',
          'HEADER__NOTIFICATIONS'
        ],
        siniestros: [
          'ACCESS',
          'LANDING__PERITO_LIST__VIEW',
          'LANDING__PERITO_FILTER__VIEW',
          'LANDING__ACTIVITIES_INDICATORS_CLAIM_ADJUSTER_BOARD__VIEW'
        ],
        login: ['ACCESS'],
        ['reset-password']: ['ACCESS'],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__EDIT',
          'CLAIM_DETAIL__REDUCED_INFO__VIEW',
          'CLAIM_DETAIL__BACK_BUTTON__VIEW'
        ]
      },
      PeritoExterno: {
        authentication: [
          'ACCESS',
          'PLATFORM_NEWS',
          'LOGIN__AGREEMENT_SERVICE'
        ],
        ['change-password']: ['ACCESS'],
        ['siniestros/detalle']: [
          'ACCESS',
          'SINIESTRO_DETAIL__PERITO__VIEW'
        ],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [
          'HEADER__LOGOUT_BUTTON__VIEW',
          'HEADER__NOTIFICATIONS'
        ],
        siniestros: [
          'ACCESS',
          'LANDING__PERITO_LIST__VIEW',
          'LANDING__PERITO_FILTER__VIEW',
          'LANDING__ACTIVITIES_INDICATORS_CLAIM_ADJUSTER_BOARD__VIEW',
          'LANDING__PERITO_FILTER__INVOICE',
          'LANDING__PERITO_INDICATORS__INVOICCE'
        ],
        login: ['ACCESS'],
        ['reset-password']: ['ACCESS'],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__EDIT',
          'CLAIM_DETAIL__REDUCED_INFO__VIEW',
          'CLAIM_DETAIL__BACK_BUTTON__VIEW'
        ]
      },
      PortalSocios: {
        authentication: ['ACCESS'],
        ['change-password']: [],
        ['siniestros/detalle']: [],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [],
        siniestros: [],
        login: [],
        ['reset-password']: [],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__VIEW',
          'CLAIM_DETAIL__COMPLETE_INFO__VIEW',
        ]
      },
      Productor: {
        authentication: [
          'ACCESS'
        ],
        ['change-password']: [],
        ['siniestros/detalle']: [
          'ACCESS',
          'SINIESTRO_DETAIL__PAS__VIEW'
        ],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [],
        siniestros: [
          'ACCESS',
          'LANDING__CLAIM_REPORT__VIEW',
          'LANDING__CLAIMS_FILTER__VIEW',
          'LANDING__CLAIMS_LIST__VIEW',
          'LANDING__PAS_INDICATORS'
        ],
        login: [],
        ['reset-password']: [],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__VIEW',
          'CLAIM_DETAIL__COMPLETE_INFO__VIEW',
          'CLAIM_DETAIL__BACK_BUTTON__VIEW'
        ]
      },
      Tallerista: {
        authentication: [
          'ACCESS',
          'LOGIN__AGREEMENT_SERVICE',
          'PLATFORM_NEWS'
        ],
        ['change-password']: ['ACCESS'],
        ['siniestros/detalle']: [],
        ['error']: ['ACCESS'],
        forbidden: ['ACCESS'],
        global: [
          'HEADER__LOGOUT_BUTTON__VIEW',
          'HEADER__NOTIFICATIONS'
        ],
        siniestros: [
          'ACCESS',
          'LANDING__CLAIM_ASSIGNMENT__VIEW',
          'LANDING__CLAIMS_FILTER__VIEW',
          'LANDING__ACTIVITIES_LIST__VIEW',
          'LANDING__ACTIVITIES_INDICATORS_BOARD__VIEW'
        ],
        login: ['ACCESS'],
        ['reset-password']: ['ACCESS'],
        statistics: [],
        'siniestros/tracking': [
          'ACCESS',
          'TRACKING__EDIT',
          'CLAIM_DETAIL__REDUCED_INFO__VIEW',
          'CLAIM_DETAIL__BACK_BUTTON__VIEW'
        ]
      }
    };
    const role = this.getLocalStorageUserInfo().userRole || this.getUserRole() || 'Anonymous';
    return PERMISSIONS[role] && PERMISSIONS[role][module] && PERMISSIONS[role][module].includes(permission);
  }
}
