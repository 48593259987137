import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Services
import { AnalyticsService, AuthenticationService } from 'app/core/services';


@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<any> {

  constructor(
    private analyticsService: AnalyticsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If we have a token parameter in the url
    if (route.queryParams['token']) {
      // Before setting pas or associated user, we check if it's a valid token.
      if (this.authenticationService.isJwtValidToken(route.queryParams['token'])) {
        this.setPasAssociatedUser(route, state);
      } else {
        this.router.navigate(['/']);
      }
    }
    if (this.authenticationService.isAuthenticated()) {
      if (state.url === '/authentication/login' || state.url === '/') {
        this.redirectSuccess();
        return false;
      } else {
        // If we have a token param in the url
        if (route.queryParams['token'] && this.authenticationService.isJwtValidToken(route.queryParams['token'])) {
          // Clear query params
          const branchQueryParam = route.queryParams['branch'] ? { branch: route.queryParams['branch'] }  : {};
          this.router.navigate([state.url.split('?')[0]], { queryParams: branchQueryParam });
        }
        return true;
      }
    } else {
      if (state.url === '/authentication/login') {
        return true;
      } else {
        this.router.navigate(['authentication/login']);
        return false;
      }
    }
  }

  canDeactivate(component, currentRoute, currentState, nextState) {
    return component.canDeactivate ? component.canDeactivate(component, currentRoute, currentState, nextState) : true;
  }

  public redirectSuccess() {
    const userInfo = this.authenticationService.getLocalStorageUserInfo();
    if (userInfo.userRole === 'Administrador') {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/siniestros']);
    }
  }

  public setPasAssociatedUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = route.queryParams['token'];
    // For security we check again if it's a valid token.
    if (this.authenticationService.isJwtValidToken(token)) {
      // Clear localstorage
      this.authenticationService.removeUserDataFromLocalStorage();
      this.authenticationService.removeAuthenticationToken();
      // Set localstorage token
      this.authenticationService.setAuthenticationToken(token);
      // Create user Information
      const userInfo = this.authenticationService.getDecodedUserInfo();
      // Set user Information
      this.authenticationService.setLocalStorageUserInfo(userInfo);
    }
  }
}
